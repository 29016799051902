import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import "./navigationLink.scss";

const NavigationLink = ({ links: { name, url } }) => (
  <Link className="link" onClick={hideMenu} to={url}>
    {name}
  </Link>
);

const hideMenu = () => {
  window.scrollTo(0, 0);
  if (window.innerWidth <= 900) {
    const mobileNav = document.querySelector(".linksBoxs");
    mobileNav.classList.remove("isActive");
    mobileNav.style.transform = "translateX(-100%)";
  }
  const queryString = window.location.search;
};

NavigationLink.propTypes = {
  links: PropTypes.shape({
    url: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }),
};

export default NavigationLink;
