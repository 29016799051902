import { Link } from "react-router-dom";
import { Map } from "../../components/Map";

import { about, gallery } from "./data";
import "./Home.scss";
import Courses from "../../components/courses/container/container";
import TopBar from "../../components/topbar/topBar";
import Navigation from "../../components/navigation/navigationBar/navigationBar";
import { Awards } from "../../components/awards/Awards";

import styled from "styled-components";
import { Loader } from "../../components/preloader/loader";
import { useState } from "react";

const StyledButton = styled.button`
  border: 3px solid rgba(21, 27, 53, 1);
  background: none;
  text-transform: uppercase;
  margin-top: 5vh;
  padding: 12px 20px 10px 20px;
  color: #225180;
  font-weight: bold;
  transition: 300ms;
  cursor: pointer;
  &:hover {
    background: rgba(21, 27, 53, 1);
    color: #fff;
  }
`;

export const Home = () => {
  const moveToTop = () => {
    window.scrollTo(0, 0);
  };

  const [loading, setLoading] = useState(true);

  setTimeout(() => {
    setLoading(false);
    console.log(loading);
  }, 1300);

  return (
    <>
      {loading && <Loader />}
      <div className="container">
        <Navigation />
        <TopBar />
        <section className="about-home-content">
          <div className="container">
            <h2>{about.title}</h2>
            <p>{about.content}</p>
          </div>
        </section>
        <Awards />
        <section className="gallery">
          <div className="container">
            <h2>{gallery.title}</h2>
            <div className="gallery-container">
              {gallery.smallGallery.map((image) => (
                <div className="image-gallery-container">
                  <img src={image} alt="gallery" />
                </div>
              ))}
            </div>
            <Link to="/galeria" onClick={moveToTop}>
              <StyledButton>Zobacz więcej</StyledButton>
            </Link>
          </div>
        </section>
        <Courses />
        <Map />
      </div>
    </>
  );
};
