import { useParams } from "react-router-dom";

import Navigation from "../../components/navigationWithBackground/navigationBar/navigationBar";
import MarginBox from "../../components/margin/margin";
import "./SingleCoursePage.scss";
import { depilation, eyebrowns, lashes, master } from "../../data/courses";
import { useState } from "react";
import { Loader } from "../../components/preloader/loader";

const SingleCoursePage = () => {
  const { category, friendlyUrl } = useParams();
  const coursesList = {
    depilacja: depilation,
    brwii: eyebrowns,
    rzesy: lashes,
    master: master,
  };

  const selectService = coursesList[category].list.filter(
    (element) => element.friendlyUrl === friendlyUrl
  )[0];

  const [loading, setLoading] = useState(true);

  setTimeout(() => {
    setLoading(false);
    console.log(loading);
  }, 1300);

  return (
    <>
      {loading && <Loader />}
      <Navigation />
      <MarginBox />
      <section className="list">
        <div className="service-container">
          <h2 className="serviceName">{selectService.serviceName}</h2>
          <div className="list-box-container-single">
            <div className="list-box">
              <h3>Opis usługi: </h3>
              <p>{selectService.shortContent}</p>
            </div>
            <div className="list-box">
              <h3>Dodatkowe informacje:</h3>
              <span>
                Czas trwania: {selectService.time}
                <br />
                <br />
                Cena indywidualna: {selectService.priceIndividual} zł
                <br />
                Cena w grupie: {selectService.priceGroup} zł
              </span>
            </div>
          </div>
          <div className="serviceLink">
            <a href={`/zamowienie/${category}/${friendlyUrl}`}>Zarezerwuj</a>
          </div>
        </div>
      </section>
    </>
  );
};

export default SingleCoursePage;
