import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./index.scss";
import { ContactInfo } from "./components/contactData/container/Contact";
import { Footer } from "./components/Footer";
import CoursesPage from "./pages/coursesPage/CoursesPage";
import { Home } from "./pages/home/Home";
import { About } from "./pages/about/About";
import { Contact } from "./pages/contact/Contact";
import { Gallery } from "./pages/gallery/Gallery";
import { Privacy } from "./pages/privacy/Privacy";
import ServicesPage from "./pages/servicesPage/ServicesPage";
import SingleServicePage from "./pages/singleServicePage/SingleServicePage";
import SingleCoursePage from "./pages/singleCoursePage/SingleCoursePage";
import OrderCourse from "./pages/order/Order";

const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="" element={<Home />} />
        <Route path="o-mnie" element={<About />} />
        <Route path="galeria" element={<Gallery />} />
        <Route path="szkolenia" element={<CoursesPage />} />
        <Route
          path="szkolenia/:category/:friendlyUrl"
          element={<SingleCoursePage />}
        />
        <Route path="atelier-urody" element={<ServicesPage />} />
        <Route
          path="atelier-urody/:category/:friendlyUrl"
          element={<SingleServicePage />}
        />
        <Route
          path="zamowienie/:category/:friendlyUrl"
          element={<OrderCourse />}
        />
        <Route path="kontakt" element={<Contact />} />
        <Route path="polityka-prywatności" element={<Privacy />} />
      </Routes>
      <ContactInfo />
      <Footer />
    </Router>
  );
};

export default App;
