import "./Contact.scss";
import MarginBox from "../../components/margin/margin";
import styled from "styled-components";
import Navigation from "../../components/navigationWithBackground/navigationBar/navigationBar";
import { images } from "../../assets/images";
import { icons } from "../../assets/icons";
import { Map } from "../../components/Map";
import { useState } from "react";
import { Loader } from "../../components/preloader/loader";

const Box = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  @media only screen and (max-width: 1100px) {
    width: 100%;
    flex-direction: column;
    justify-content: center;
    padding: 3vh 5vw;
    box-sizing: border-box;
  }
`;

const BoxContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  @media only screen and (max-width: 1100px) {
    width: 100%;
    align-items: center;
  }
`;

const Image = styled.img`
  height: 30px;
  margin-right: 25px;
  @media only screen and (max-width: 1100px) {
    margin-right: 0px;
    height: 60px;
    margin-bottom: 10px;
  }
`;

const HeadingText = styled.h2`
  margin-bottom: 0px;
  font-size: 20px;
  text-transform: uppercase;
  text-align: center;
  color: #814c53;
`;

const Description = styled.h3`
  margin-top: 5px;
  color: rgba(21, 27, 53, 1);
  text-align: center;
  font-size: 15px;
  text-transform: uppercase;
`;

export const Contact = () => {
  const [loading, setLoading] = useState(true);

  setTimeout(() => {
    setLoading(false);
    console.log(loading);
  }, 1300);

  return (
    <>
      {loading && <Loader />}
      <div className="container">
        <Navigation />
        <MarginBox />
        <section className="contact-content">
          <div className="container">
            <div className="image-container">
              <img src={images.contact} alt="angelika olejnik" />
            </div>
            <div className="contact">
              <h2>Kontakt</h2>

              <Box>
                <Image src={icons.phone} alt="phone"></Image>
                <BoxContainer>
                  <HeadingText>Telefon</HeadingText>
                  <Description>794 714 017</Description>
                </BoxContainer>
              </Box>
              <Box>
                <Image src={icons.mail} alt="mail"></Image>
                <BoxContainer>
                  <HeadingText>E-mail</HeadingText>
                  <Description>Info@ariaszkolenia.pl</Description>
                </BoxContainer>
              </Box>
              <Box>
                <Image src={icons.location} alt="location"></Image>
                <BoxContainer>
                  <HeadingText>Adres</HeadingText>
                  <Description>Główna 2, 05-200 Stare Grabie</Description>
                </BoxContainer>
              </Box>
            </div>
          </div>
        </section>
        <Map />
      </div>
    </>
  );
};
