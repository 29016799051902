export const lashes = {
  title: "Stylizacja rzęs",
  list: [
    {
      serviceName: "Przedłużanie rzęs metodą 1:1",
      shortContent:
        "Szkolenie dla osób nieposiadających doświadczenia z zakresu stylizacji rzęs i chciałyby poznać jej tajniki. Jest również skierowane dla stylistek z wieloletnią przerwą w pracy w ramach doszkolenia.",
      content:
        "Szkolenie dla os&oacute;b nieposiadających doświadczenia z zakresu stylizacji rzęs i chciałyby poznać jej tajniki. Jest r&oacute;wnież skierowane dla stylistek z wieloletnią przerwą w pracy w ramach doszkolenia.&nbsp;PROGRAM SZKOLENIA:TEORIA&nbsp;- Budowa i fizjologia rzęs- Materiałoznawstwo&nbsp;- Szczeg&oacute;łowe om&oacute;wienie skład&oacute;w i działania klej&oacute;w do rzęs&nbsp;- Przeciwwskazania, bezpieczeństwo i higiena pracy(BHP)&nbsp;- Podstawowe modelowanie oczu&nbsp;- Przygotowanie rzęs przed/pielęgnacja rzęs po zabiegu&nbsp;- Zasady prawidłowej aplikacji rzęs&nbsp;- Procedura zabiegowa&nbsp;- Uzupełnianie rzęs&nbsp;- Zdejmowanie rzęs&nbsp;- Najczęstsze błędy, praca z trudnymi rzęsami&nbsp;- Alergia i podrażnienie oczu&nbsp;- Pierwsze kroki w stylizacji rzęs&nbsp;- Kierunkowanie rzęs&nbsp;- Dob&oacute;r grubości i długości do kondycji i stanu rzęs naturalnych&nbsp;PRAKTYKA&nbsp;- Nauka pracy z pęsetami&nbsp;- Praca na gł&oacute;wce treningowej&nbsp;- Wykonanie zabiegu na modelce&nbsp;",
      priceIndividual: "2600",
      priceGroup: "2200",
      time: "2 dni (16h)",
      friendlyUrl: "przedluzanie-rzes-1-do-1",
    },
    {
      serviceName: "Przedłużanie i zagęszczanie rzęs metodą Light Volume",
      shortContent:
        "Szkolenie wprowadzające w świat objętości dla początkujących stylistek, które mają opanowaną metodę 1:1.",
      content:
        "Szkolenie wprowadzające w świat objętości dla początkujących stylistek, kt&oacute;re mają opanowaną metodę 1:1.&nbsp;PROGRAM SZKOLENIA:TEORIA&nbsp;- Materiałoznawstwo&nbsp;- Przeciwwskazania do wykonania zabiegu&nbsp;- Modelowanie oczu średnio zaawansowane&nbsp;- Kierunkowanie rzęs&nbsp;- Dob&oacute;r grubości i długości do kondycji i stanu rzęs naturalnych&nbsp;- Om&oacute;wienie metod objętościowych 2-4D&nbsp;- Techniki tworzenia kępek (m. in. drapanie, przekładanie , bujanie, w palcach,szarpanie)&nbsp;- Procedura zabiegowa&nbsp;- Najczęstsze błędy przy stylizacji rzęs&nbsp;- Alergia i podrażnienia oczu&nbsp;- Sposoby na składające się kępki&nbsp;- Prawidłowe metody przyklejania kępek &nbsp;- Od czego zależy trwałość aplikacji &nbsp;- Mieszanie skręt&oacute;w- Idealna kępka- Dob&oacute;r pęsety do danej techniki- Rzędowość rzęs- Triki ułatwiające pracęPRAKTYKA&nbsp;- Ćwiczenia na gąbce- Wykonanie stylizacji na modelce&nbsp;",
      priceIndividual: "2200",
      priceGroup: "1700",
      time: "1 dzień (8h)",
      friendlyUrl: "przedluzanie-zageszczanie-light-volume",
    },
    {
      serviceName: "Przedłużanie i zagęszczanie rzęs metodą Mega Volume",
      shortContent:
        "Zaawansowane szkolenie z zakresu metod wolumetrycznych przeznaczone dla osób średnio-zaawansowanych i zaawansowanych.",
      content:
        "Zaawansowane szkolenie z zakresu metod wolumetrycznych przeznaczone dla osób średnio-zaawansowanych i zaawansowanych.TEORIA- Materiałoznawstwo&nbsp;- Przeciwwskazania do wykonania zabiegu&nbsp;- Modelowanie oczu średnio zaawansowane&nbsp;- Kierunkowanie rzęs i rzędowość rzęs- Dob&oacute;r grubości i długości do kondycji i stanu rzęs naturalnych&nbsp;- Omówienie metod objętościowych 5D+- Techniki tworzenia kępek (m. in. drapanie, przekładanie , bujanie, w palcach,szarpanie)&nbsp;- Procedura zabiegowa&nbsp;- Najczęstsze błędy przy stylizacji rzęs&nbsp;- Alergia i podrażnienia oczu&nbsp;- Sposoby na składające się kępki&nbsp;- Prawidłowe metody przyklejania kępek &nbsp;- Od czego zależy trwałość aplikacji &nbsp;- Mieszanie skręt&oacute;w- Idealna kępka- Dob&oacute;r pęsety do danej techniki- Rzędowość rzęs- Triki ułatwiające pracę- Praca z kolorem- Eliminacja sklejek-Przedłużanie rzęs dolnychProtezy międzyrzęsowePRAKTYKA&nbsp;- Ćwiczenia na gąbce- Wykonanie stylizacji na modelce&nbsp;",
      priceIndividual: "2200",
      priceGroup: "1700",
      time: "1 dzień (8h)",
      friendlyUrl: "przedluzanie-zageszczanie-mega-volume",
    },
    {
      serviceName: "Lifting, laminacja i botox rzęs",
      shortContent:
        "Szkolenie dla każdego, kto chce poznać zabieg laminacji rzęs. Szczególnie dla wizażystek, kosmetyczek, stylistek rzęs oraz brwi jako wzbogacenie swojej oferty. Idealny jako pierwsze szkolenie dotyczące oprawy oka.",
      content:
        "Szkolenie dla każdego, kto chce poznać zabieg laminacji rzęs. Szczególnie dla wizażystek, kosmetyczek, stylistek rzęs oraz brwi jako wzbogacenie swojej oferty. Idealny jako pierwsze szkolenie dotyczące oprawy oka.TEORIA- Omówienie zabiegu liftingu /laminacji rzęs- Budowa rzęs oraz cykl wzrostu włosa- Omówienie wskazań i przeciwwskazań do zabiegu- BHP i ergonomia pracy- Charakterystyka materiałów- Przygotowanie stanowiska pracy- Odpowiedni dobór wałków- Etapy zabiegu krok po kroku- Farbowanie rzęs- Omówienie istoty Botoxu- Problemy i błędy podczas zabiegu- Odwracanie efektu zabiegu- Zalecenia pielęgnacyjne- Wszystko o odzywkach do rzęsPRAKTYKA&nbsp;- Wykonanie zabiegu na 2 modelkach",
      priceIndividual: "1600",
      priceGroup: "1300",
      time: "1 dzień (8h)",
      friendlyUrl: "lifting-laminacja-botox-rzes",
    },
  ],
};
export const eyebrowns = {
  title: "Stylizacja brwi",
  list: [
    {
      serviceName: "Henna pudrowa i koloryzacja brwi",
      shortContent:
        "Szkolenie z zakresu stylizacji brwi. Obejmuje zagadnienia z koloryzacji, geometrii i architektury brwi, henny pudrowej, depilacji produktami Lycon",
      content:
        "Szkolenie z zakresu stylizacji brwi. Obejmuje zagadnienia z koloryzacji, geometrii i architektury brwi, henny pudrowej, depilacji produktami LyconTEORIA- Materiałoznawstwo- Brow Xenna ,Refectocil, Pove,Elan, Thuya, Lycon- Różnice miedzy farbką a henną- Mapowanie brwi na kilka sposobów- Budowa włosa i skory- Przeciwwskazania , zalecenia- Problematyczne brwi,- Dobór brwi do kształtów twarzy- Alergia- Asymetria brwi- Praca z brow pastą- Depilacja brwi woskiem Lycon- Makijaż korygujący- Efekt ombre- Zabiegi pielęgnacyjne brwiPRAKTYKA&nbsp;- Praca na 2 modelkach",
      priceIndividual: "1600",
      priceGroup: "1300",
      time: "1 dzień (8h)",
      friendlyUrl: "henna-pudrowa-koloryzacja-brwi",
    },
    {
      serviceName: "Laminacja brwi",
      shortContent:
        "Szkolenie z zakresu laminacji brwi – obecnie najpopularniejszy zabieg stylizacji brwi. Jest dla każdego, szczególnie dla wizażystek, kosmetyczek, stylistek rzęs oraz brwi jako wzbogacenie swojej oferty.",
      content:
        "Szkolenie z zakresu laminacji brwi – obecnie najpopularniejszy zabieg stylizacji brwi. Jest dla każdego, szczególnie dla wizażystek, kosmetyczek, stylistek rzęs oraz brwi jako wzbogacenie swojej oferty.TEORIA- Czym jest laminacja brwi?- Wskazania do zabiegu- Korzyści z laminacji brwi- Stanowisko pracy stylistki- Bezpieczeństwo i higiena pracy- Przeciwwskazania do zabiegu- Budowa włosa- Cykl wzrostu włosków na brwiach- Strona chemiczna zabiegu- Opis produktów do zabiegu- Odpowiedni dobór czasu utrzymania preparatu na podstawie klasyfikacji typu włosów- Koloryzacja po zabiegu – w jakim celu?- Schemat działania farby- Dlaczego nie używamy henny po laminacji brwi?- Przygotowanie skóry do zabiegu- Metody regulacji brwi- Regulacja brwi woskiem Lycon- Procedura zabiegowa- Schematy układu włosa przy laminacji- Geometria brwi- Zalecenia pozabiegowePRAKTYKA&nbsp;- Praca na 2 modelkach",
      priceIndividual: "1600",
      priceGroup: "1300",
      time: "1 dzień (8h)",
      friendlyUrl: "laminacja-brwi",
    },
    {
      serviceName: "Stylizacja brwi Combo",
      shortContent:
        "Jest to KOMPLEKSOWE szkolenie, na którym nauczysz się wszystkich najpopularniejszych technik stylizacji. Obejmuje zagadnienia z koloryzacji, laminacji, kształtowania i rekonstrukcji brwi, henny pudrowej.",
      content:
        "Jest to KOMPLEKSOWE szkolenie, na którym nauczysz się wszystkich najpopularniejszych technik stylizacji. Obejmuje zagadnienia z koloryzacji, laminacji, kształtowania i rekonstrukcji brwi, henny pudrowej.TEORIA- Materiałoznawstwo- Brow Xenna ,Refectocil, Pove,Elan, Thuya, Lycon, inLei, Nikk Mole- Różnice miedzy farbką a henną- Mapowanie brwi na kilka sposobów- Budowa włosa i skory- Przeciwwskazania i zalecenia pozabiegowe- Problematyczne brwi,- Dobór brwi do kształtów twarzy- Alergia- Asymetria brwi- Praca z brow pastą i kredką pudrową- Depilacja brwi woskiem Lycon- Makijaż korygujący- Efekt ombre- Zabiegi pielęgnacyjne brwi- Laminacja brwii- Gold Brows By AriaPRAKTYKA&nbsp;- Praca na 4 modelkach",
      priceIndividual: "2600",
      priceGroup: "2100",
      time: "2 dni (16h)",
      friendlyUrl: "stylizacja-brwi-combo",
    },
  ],
};
export const master = {
  title: "Master",
  list: [
    {
      serviceName: "Pracuj jak Mistrz w swoim salonie",
      shortContent:
        "Szkolenie dla przyszłych mistrzyń stylizacji rzęs przygotowujące do startu na mistrzostwach stacjonarnych oraz online. Podczas szkolenia uczestniczki mogą zawalczyć o swój pierwszy puchar za najlepszą pracę podczas treningu przed mistrzowskiego. Szkolenie to pozwoli Ci również nabyć wiedzę, dzięki której będziesz pracować jak mistrz w swoim gabinecie",
      content:
        "Szkolenie dla przyszłych mistrzyń stylizacji rzęs przygotowujące do startu na mistrzostwach stacjonarnych oraz online. Podczas szkolenia uczestniczki mogą zawalczyć o swój pierwszy puchar za najlepszą pracę podczas treningu przed mistrzowskiego. Szkolenie to pozwoli Ci również nabyć wiedzę, dzięki której będziesz pracować jak mistrz w swoim gabinecieTEORIA- Motywacje i korzyści z uczestnictwa w mistrzostwach- Podział na kategorie i nominacje- Metody podstawowe i wolumetryczne- Regulamin mistrzostw- Kufer mistrzyni- Punktacja, punkty ujemne i dyskwalifikacja- Charakterystyka mistrzostw online i live- Idelana modelka- Odpowiednia gramatura do każdej metody- Rozkład czasu pracy podczas mistrzostw stacjonarnych- Zdjęcia - jak prawidłowo zrobić zdjęcia pod mistrzostwa onlinePRAKTYKA&nbsp;- Wykonanie aplikacji na jednej modelce wykorzystując poznaną wiedzę (bez limitu czasowego) oraz na dwóch modelkach w czasie, jaki obowiązuje na mistrzostwach. - Pierwsza modelka - metoda 1:1, druga modelka metoda objętościowa dowolnie wybrana przez kursantkę.",
      priceIndividual: "Nie dotyczy",
      priceGroup: "2500",
      time: "2 dni (15h)",
      friendlyUrl: "pracuj-jak-mistrz-w-swoim-salonie",
    },
    {
      serviceName: "Mistrzowskie brwi",
      shortContent:
        "Szkolenie dla stylistek chcących osiągnąć poziom mistrzowski z zakresu stylizacji brwi. Jest to przygotowanie do mistrzostw stacjonarnych oraz online z henny pudrowej i naturalnej stylizacji brwi. Podczas szkolenia najlepsza praca treningowa zostanie nagrodzona pucharem",
      content:
        "Szkolenie dla stylistek chcących osiągnąć poziom mistrzowski z zakresu stylizacji brwi. Jest to przygotowanie do mistrzostw stacjonarnych oraz online z henny pudrowej i naturalnej stylizacji brwi. Podczas szkolenia najlepsza praca treningowa zostanie nagrodzona pucharemTEORIA- Motywacje i korzyści z uczestnictwa w mistrzostwach- Podział na kategorie i nominacje- Henna pudrowa i naturalna stylizacja brwi- Regulamin mistrzostw- Zaplanowanie udziału w mistrzostwach- Kufer mistrzyni- Punktacja, punkty ujemne i dyskwalifikacja- Charakterystyka mistrzostw online i live- Idelana modelka- Rozkład czasu pracy podczas mistrzostw stacjonarnych- Zdjęcia - jak prawidłowo zrobić zdjęcia pod mistrzostwa onlinePRAKTYKA&nbsp;- Wykonanie stylizacji na 2 modelkach",
      priceGroup: "2000",
      priceIndividual: "Nie dotyczy",
      time: "1 dzień (10h)",
      friendlyUrl: "mistrzowskie-brwi",
    },
  ],
};
export const depilation = {
  title: "Depilacja",
  list: [
    {
      serviceName: "Depilacja twarzy",
      shortContent:
        "Szkolenie przeznaczone jest dla Barberów, Stylistów brwi, Linergistów, Specjalistów Stylizacji Rzęs, Makijażystów oraz Kosmetologów lub studentów Kosmetologii i Fizjoterapii.",
      content:
        "Szkolenie przeznaczone jest dla Barberów, Stylistów brwi, Linergistów, Specjalistów Stylizacji Rzęs, Makijażystów oraz Kosmetologów lub studentów Kosmetologii i Fizjoterapii.TEORIA- Zapoznanie z firmą Lycon- Omówienie produktów Lycon- Wsazania i przeciwwsazania do depilacji woskiem- Zalecenia pozabiegowePRAKTYKA&nbsp;- Pokaz prawidłowej aplikacji wosków Lycon- Wykonanie zabiegu depilacji: górnej wargi, brody, nosa, baków, skroni, policzków, brwi",
      priceGroup: "550",
      priceIndividual: "Nie dotyczy",
      time: "1 dzień (6h)",
      friendlyUrl: "depilacja-twarzy",
    },
  ],
};
