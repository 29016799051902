import logo from "./logo.svg";
import fb from "./fb.svg";
import ig from "./ig.svg";
import saloner from "./saloner.svg";
import lashLogo from "./lash-logo.svg";
import phone from "./phone.svg";
import location from "./location.svg";
import mail from "./mail.svg";

interface Icons {
  logo: string;
  fb: string;
  ig: string;
  saloner: string;
  lashLogo: string;
  mail: string;
  location: string;
  phone: string;
}

export const icons: Icons = {
  logo,
  fb,
  ig,
  saloner,
  lashLogo,
  phone,
  location,
  mail,
};
