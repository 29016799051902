import { awardsData } from "./Awards-data";
import "./Awards.scss";

export const Awards = () => (
  <div className="awards-container">
    <h2>{awardsData.title}</h2>
    {awardsData.data.map((award, index) => (
      <div className="award-box" key={index}>
        <h3>{award.award}</h3>
        <h3>{award.category}</h3>
        <h3>{award.placeInContest}</h3>
      </div>
    ))}
  </div>
);

export default Awards;
