import styled from "styled-components";
import { icons } from "../../../assets/icons";

const Container = styled.div`
  width: 150px;
  height: 100%;
  text-align: center;
  margin-left: auto;
  @media only screen and (max-width: 860px) {
    width: 80px;
    margin-bottom: 3vh;
    margin: 0 auto;
    text-align: center;
  }
`;

const Socials = styled.div`
  display: flex;
  flex-direction: column;
  justify-items: flex-start;
  width: 150px;
`;

const Link = styled.a`
  display: flex;
  margin-right: auto;
  text-align: left;
  margin-bottom: 20px;
  align-items: center;
  color: #fff;
  text-decoration: none;
`;

const Title = styled.h5`
  margin: 0px;
  margin-left: 10px;
`;

const SocialsBox = () => (
  <Container>
    <Socials>
      <Link href="https://www.facebook.com/AriaMUA" target="_blank">
        <img src={icons.fb} alt="facebook" />
        <Title>Aria visage</Title>
      </Link>

      <Link
        href="https://www.instagram.com/aria.angelika.olejnik/?igshid=ZDdkNTZiNTM%3D"
        target="_blank"
      >
        <img src={icons.ig} alt="instagram" />
        <Title>Aria visage</Title>
      </Link>
      <Link
        href="https://www.instagram.com/aria.aroma.atelier/?igshid=ZDdkNTZiNTM%3D"
        target="_blank"
      >
        <img src={icons.ig} alt="instagram" />
        <Title>Aroma atelier</Title>
      </Link>
      <Link
        href="https://booksy.com/pl-pl/104936_aria-angelika-olejnik-atelier-urody-i-akademia_brwi-i-rzesy_4277_wolomin?do=invite#ba_s=dl_1"
        target="_blank"
      >
        <img width="100px" src={icons.saloner} alt="saloner" />
      </Link>
    </Socials>
  </Container>
);

export default SocialsBox;
