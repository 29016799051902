import Navigation from "../../components/navigationWithBackground/navigationBar/navigationBar";
import MarginBox from "../../components/margin/margin";
import "./ServicesPage.scss";
import {
  eyebrowns,
  depilation,
  lashes,
  specialLashes,
  cosmetics,
  tan,
  esteticsMed,
  terapeutics,
} from "../../data/services";
import { useState } from "react";
import { Loader } from "../../components/preloader/loader";

const ServicesPage = () => {
  const [loading, setLoading] = useState(true);

  setTimeout(() => {
    setLoading(false);
    console.log(loading);
  }, 1300);

  return (
    <>
      {loading && <Loader />}
      <Navigation />
      <MarginBox />
      <section className="list">
        <div className="container">
          <h2>{lashes.title}</h2>
          {lashes.list.map((element, index) => (
            <div className="list-box-container" key={index}>
              <div className="list-box">
                <h3>{element.serviceName}</h3>
                {element.shortContent.toUpperCase() !==
                  element.serviceName.toUpperCase() && (
                  <p>
                    {element.shortContent.length > 255
                      ? element.shortContent.slice(0, 260) + " [...]"
                      : element.shortContent}
                  </p>
                )}
                <div>
                  <a href="https://booksy.com/pl-pl/104936_aria-angelika-olejnik-atelier-urody-i-akademia_brwi-i-rzesy_4277_wolomin?do=invite#ba_s=dl_1">
                    Umów wizytę
                  </a>
                  <a href={`atelier-urody/rzesy/${element.friendlyUrl}`}>
                    Szczegóły
                  </a>
                </div>
              </div>
              <div className="list-box">
                <h3>Informacje:</h3>
                <span>
                  Czas trwania: {element.time}
                  <br />
                  Cena: {element.price}
                </span>
              </div>
            </div>
          ))}
        </div>
        <div className="container">
          <h2>{specialLashes.title}</h2>
          {specialLashes.list.map((element, index) => (
            <div className="list-box-container" key={index}>
              <div className="list-box">
                <h3>{element.serviceName}</h3>
                {element.shortContent.toUpperCase() !==
                  element.serviceName.toUpperCase() && (
                  <p>
                    {element.shortContent.length > 255
                      ? element.shortContent.slice(0, 260) + " [...]"
                      : element.shortContent}
                  </p>
                )}
                <div>
                  <a href="https://booksy.com/pl-pl/104936_aria-angelika-olejnik-atelier-urody-i-akademia_brwi-i-rzesy_4277_wolomin?do=invite#ba_s=dl_1">
                    Umów wizytę
                  </a>
                  <a href={`atelier-urody/specjalne/${element.friendlyUrl}`}>
                    Szczegóły
                  </a>
                </div>
              </div>
              <div className="list-box">
                <h3>Informacje:</h3>
                <span>
                  Czas trwania: {element.time}
                  <br />
                  Cena: {element.price}
                </span>
              </div>
            </div>
          ))}
        </div>
        <div className="container">
          <h2>{eyebrowns.title}</h2>
          {eyebrowns.list.map((element, index) => (
            <div className="list-box-container" key={index}>
              <div className="list-box">
                <h3>{element.serviceName}</h3>
                {element.shortContent.toUpperCase() !==
                  element.serviceName.toUpperCase() && (
                  <p>
                    {element.shortContent.length > 255
                      ? element.shortContent.slice(0, 260) + " [...]"
                      : element.shortContent}
                  </p>
                )}
                <div>
                  <a href="https://booksy.com/pl-pl/104936_aria-angelika-olejnik-atelier-urody-i-akademia_brwi-i-rzesy_4277_wolomin?do=invite#ba_s=dl_1">
                    Umów wizytę
                  </a>
                  <a href={`atelier-urody/rytualy/${element.friendlyUrl}`}>
                    Szczegóły
                  </a>
                </div>
              </div>
              <div className="list-box">
                <h3>Informacje:</h3>
                <span>
                  Czas trwania: {element.time}
                  <br />
                  Cena: {element.price}
                </span>
              </div>
            </div>
          ))}
        </div>
        <div className="container">
          <h2>{tan.title}</h2>
          {tan.list.map((element, index) => (
            <div className="list-box-container" key={index}>
              <div className="list-box">
                <h3>{element.serviceName}</h3>
                {element.shortContent.toUpperCase() !==
                  element.serviceName.toUpperCase() && (
                  <p>
                    {element.shortContent.length > 255
                      ? element.shortContent.slice(0, 260) + " [...]"
                      : element.shortContent}
                  </p>
                )}
                <div>
                  <a href="https://booksy.com/pl-pl/104936_aria-angelika-olejnik-atelier-urody-i-akademia_brwi-i-rzesy_4277_wolomin?do=invite#ba_s=dl_1">
                    Umów wizytę
                  </a>
                  <a href={`atelier-urody/opalanie/${element.friendlyUrl}`}>
                    Szczegóły
                  </a>
                </div>
              </div>
              <div className="list-box">
                <h3>Informacje:</h3>
                <span>
                  Czas trwania: {element.time}
                  <br />
                  Cena: {element.price}
                </span>
              </div>
            </div>
          ))}
        </div>
        <div className="container">
          <h2>{depilation.title}</h2>
          {depilation.list.map((element, index) => (
            <div className="list-box-container" key={index}>
              <div className="list-box">
                <h3>{element.serviceName}</h3>
                {element.shortContent.toUpperCase() !==
                  element.serviceName.toUpperCase() && (
                  <p>
                    {element.shortContent.length > 255
                      ? element.shortContent.slice(0, 260) + " [...]"
                      : element.shortContent}
                  </p>
                )}
                <div>
                  <a href="https://booksy.com/pl-pl/104936_aria-angelika-olejnik-atelier-urody-i-akademia_brwi-i-rzesy_4277_wolomin?do=invite#ba_s=dl_1">
                    Umów wizytę
                  </a>
                  <a href={`atelier-urody/depilacja/${element.friendlyUrl}`}>
                    Szczegóły
                  </a>
                </div>
              </div>
              <div className="list-box">
                <h3>Informacje:</h3>
                <span>
                  Czas trwania: {element.time}
                  <br />
                  Cena: {element.price}
                </span>
              </div>
            </div>
          ))}
        </div>
        <div className="container">
          <h2>{cosmetics.title}</h2>
          {cosmetics.list.map((element, index) => (
            <div className="list-box-container" key={index}>
              <div className="list-box">
                <h3>{element.serviceName}</h3>
                {element.shortContent.toUpperCase() !==
                  element.serviceName.toUpperCase() && (
                  <p>
                    {element.shortContent.length > 255
                      ? element.shortContent.slice(0, 260) + " [...]"
                      : element.shortContent}
                  </p>
                )}
                <div>
                  <a href="https://booksy.com/pl-pl/104936_aria-angelika-olejnik-atelier-urody-i-akademia_brwi-i-rzesy_4277_wolomin?do=invite#ba_s=dl_1">
                    Umów wizytę
                  </a>
                  <a href={`atelier-urody/kosmetyka/${element.friendlyUrl}`}>
                    Szczegóły
                  </a>
                </div>
              </div>
              <div className="list-box">
                <h3>Informacje:</h3>
                <span>
                  Czas trwania: {element.time}
                  <br />
                  Cena: {element.price}
                </span>
              </div>
            </div>
          ))}
        </div>
        <div className="container">
          <h2>{esteticsMed.title}</h2>
          {esteticsMed.list.map((element, index) => (
            <div className="list-box-container" key={index}>
              <div className="list-box">
                <h3>{element.serviceName}</h3>
                {element.shortContent.toUpperCase() !==
                  element.serviceName.toUpperCase() && (
                  <p>
                    {element.shortContent.length > 255
                      ? element.shortContent.slice(0, 260) + " [...]"
                      : element.shortContent}
                  </p>
                )}
                <div>
                  <a href="https://booksy.com/pl-pl/104936_aria-angelika-olejnik-atelier-urody-i-akademia_brwi-i-rzesy_4277_wolomin?do=invite#ba_s=dl_1">
                    Umów wizytę
                  </a>
                  <a
                    href={`atelier-urody/medycyna_estetyczna/${element.friendlyUrl}`}
                  >
                    Szczegóły
                  </a>
                </div>
              </div>
              <div className="list-box">
                <h3>Informacje:</h3>
                <span>
                  Czas trwania: {element.time}
                  <br />
                  Cena: {element.price}
                </span>
              </div>
            </div>
          ))}
        </div>
        <div className="container">
          <h2>{terapeutics.title}</h2>
          {terapeutics.list.map((element, index) => (
            <div className="list-box-container" key={index}>
              <div className="list-box">
                <h3>{element.serviceName}</h3>
                {element.shortContent.toUpperCase() !==
                  element.serviceName.toUpperCase() && (
                  <p>
                    {element.shortContent.length > 255
                      ? element.shortContent.slice(0, 260) + " [...]"
                      : element.shortContent}
                  </p>
                )}
                <div>
                  <a href="https://booksy.com/pl-pl/104936_aria-angelika-olejnik-atelier-urody-i-akademia_brwi-i-rzesy_4277_wolomin?do=invite#ba_s=dl_1">
                    Umów wizytę
                  </a>
                  <a href={`atelier-urody/terapie/${element.friendlyUrl}`}>
                    Szczegóły
                  </a>
                </div>
              </div>
              <div className="list-box">
                <h3>Informacje:</h3>
                <span>
                  Czas trwania: {element.time}
                  <br />
                  Cena: {element.price}
                </span>
              </div>
            </div>
          ))}
        </div>
      </section>
    </>
  );
};

export default ServicesPage;
