import styled from "styled-components";
import { icons } from "../../../assets/icons";

const Logo = styled.img`
  position: absolute;
  top: 75%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
  @media only screen and (max-width: 380px) {
    width: 70%;
  }
`;

const LogoBox = () => <Logo src={icons.lashLogo} alt="logo" />;

export default LogoBox;
