import "./About.scss";
import MarginBox from "../../components/margin/margin";
import { about } from "../home/data";
import Navigation from "../../components/navigationWithBackground/navigationBar/navigationBar";
import { images } from "../../assets/images";
import { Loader } from "../../components/preloader/loader";
import { useState } from "react";

export const About: React.FC = () => {
  const [loading, setLoading] = useState(true);

  setTimeout(() => {
    setLoading(false);
    console.log(loading);
  }, 1300);

  return (
    <>
      {loading && <Loader />}
      <div className="container">
        <Navigation />
        <MarginBox />
        <section className="about-content">
          <div className="container">
            <div className="image-container">
              <img src={images.about} alt="angelika olejnik" />
            </div>
            <div className="about">
              <h2>{about.title}</h2>
              <p>{about.content}</p>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};
