import { useState } from "react";
import Margin from "../../components/margin/margin";
import Navigation from "../../components/navigationWithBackground/navigationBar/navigationBar";
import { Loader } from "../../components/preloader/loader";
import { gallery } from "./data";
import "./Gallery.scss";

export const Gallery = () => {
  const [loading, setLoading] = useState(true);

  setTimeout(() => {
    setLoading(false);
    console.log(loading);
  }, 1300);

  return (
    <>
      {loading && <Loader />}
      <div className="container">
        <Navigation />
        <Margin />
        <section className="gallery">
          <div className="container">
            <h2>{gallery.title}</h2>
            <div className="gallery-container">
              {gallery.gallery.map((image) => (
                <div className="image-gallery-container">
                  <img src={image} alt="gallery" />
                </div>
              ))}
            </div>
          </div>
        </section>
      </div>
    </>
  );
};
