import styled from "styled-components";
import { Link } from "react-router-dom";
import { icons } from "../../../assets/icons";

const Image = styled.img`
    height: 90%;
    position relative;
    top: 50%;
    transform: translateY(-50%);
`;

const NavigationLogo = () => (
  <Link className="logoBox" to="/">
    <Image src={icons.logo} alt="logo" />
  </Link>
);

export default NavigationLogo;
