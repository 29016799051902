import angelika_olejnik from "./start.png";
import contest from "./min/contest-min.jpg";
import about from "./min/about-min.jpg";
import contact from "./min/contact-min.jpg";
import g1 from "./min/g1-min.jpg";
import g2 from "./min/g2-min.jpg";
import g3 from "./min/g3-min.jpg";
import g4 from "./min/g4-min.jpg";
import g5 from "./min/g5-min.jpg";
import g6 from "./min/g6-min.jpg";
import g7 from "./min/g7-min.jpg";
import g8 from "./min/g8-min.jpg";
import g9 from "./min/g9-min.jpg";
import g10 from "./min/g10-min.jpg";
import g11 from "./min/g11-min.jpg";
import g12 from "./min/g12-min.jpg";

interface Images {
  angelika_olejnik: string;
  about: string;
  contest: string;
  contact: string;
  gallery: string[];
  smallGallery: string[];
}

export const images: Images = {
  angelika_olejnik,
  about,
  contest,
  contact,
  gallery: [g3, g2, g4, g5, g6, g7, g8, g9, g10, g11, g12, g1],
  smallGallery: [g9, g2, g3, g4],
};
