import { useParams } from "react-router-dom";
import Navigation from "../../components/navigationWithBackground/navigationBar/navigationBar";
import MarginBox from "../../components/margin/margin";
import "./SingleServicePage.scss";
import {
  cosmetics,
  depilation,
  esteticsMed,
  eyebrowns,
  lashes,
  specialLashes,
  tan,
  terapeutics,
} from "../../data/services";
import { useState } from "react";
import { Loader } from "../../components/preloader/loader";

const SingleServicePage = () => {
  const { friendlyUrl, category } = useParams();
  const servicesList = {
    kosmetyka: cosmetics,
    depilacja: depilation,
    brwii: eyebrowns,
    rzesy: lashes,
    specjalne: specialLashes,
    opalanie: tan,
    medycyna_estetyczna: esteticsMed,
    terapie: terapeutics,
  };

  const selectService = servicesList[category].list.filter(
    (element) => element.friendlyUrl === friendlyUrl
  )[0];

  {
    const [loading, setLoading] = useState(true);

    setTimeout(() => {
      setLoading(false);
      console.log(loading);
    }, 1300);

    return (
      <>
        {loading && <Loader />}
        <Navigation />
        <MarginBox />
        <section className="list">
          <div className="service-container">
            <h2 className="serviceName">{selectService.serviceName}</h2>
            <div className="list-box-container-single">
              <div className="list-box">
                <h3>Opis usługi: </h3>
                <p>{selectService.shortContent}</p>
              </div>
              <div className="list-box">
                <h3>Dodatkowe informacje:</h3>
                <span>
                  Czas trwania: {selectService.time}
                  <br />
                  Cena: {selectService.price}
                </span>
              </div>
            </div>
            <div className="serviceLink">
              <a href="https://booksy.com/pl-pl/104936_aria-angelika-olejnik-atelier-urody-i-akademia_brwi-i-rzesy_4277_wolomin?do=invite#ba_s=dl_1">
                Umów wizytę
              </a>
            </div>
          </div>
        </section>
      </>
    );
  }
};

export default SingleServicePage;
