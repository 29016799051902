import "./Footer.scss";

export const Footer = () => (
  <div className="footer-container">
    <p>COPYRIGHT BY ANGELIKA OLEJNIK</p>
    <p>
      <a href="/polityka-prywatności">POLITYKA PRYWATNOŚCI</a>
    </p>
  </div>
);
