export const awardsData = {
  title: "Wyróżnienia i certyfikaty",
  data: [
    {
      award: "Lash Mafia Ukraina",
      placeInContest:
        "I miejsce Lash Art, II miejsce magia odcieni brązu, III miejsce 2D",
      category: "kategoria Instruktor",
    },
    {
      award: "Lash.pl Poznań",
      placeInContest:
        "III miejsce 1:1, III miejsce 4-6d, nagroda specjalna od Moniki Góreckiej, nagroda specjalna od Grażyny Kleby",
      category: "kategoria Expert (instruktor)",
    },
    {
      award: "Polskie mistrzostwa Stylizacji rzęs Warszawa",
      placeInContest: "II miejsce",
      category: "",
    },
    {
      award: "Amber Eyes Gdańsk",
      placeInContest:
        "II miejsce 1:1 Expert, III miesjce 2/3d Expert, III miejsce Lash Lifting, III miejsce naturalna stylizacja brwi, nagroda specjalna od Marzeny Wetmańskiej za najlepszą pracę 1:1",
      category: "",
    },
    {
      award: "Lash Week Wrocław",
      placeInContest: "III miejsce 2/3d",
      category: "kategoria Elite, asystentka sędziego",
    },
    {
      award: "Gold Lash Championship Scotland Szkocja",
      placeInContest:
        "II miejsce Lash Lifting Expert, wyróżnienie od Elizy Borowiak",
      category: "",
    },
    {
      award: "Lash&Brow Channel The Americas Italia",
      placeInContest: "III miejsce Soft Volume 2/4d Master",
      category: "",
    },
    {
      award: "Race for the lash cup, Warszawa",
      placeInContest: "II miejsce 1:1, II miejsce 2/3d Expert",
      category: "",
    },
    {
      award: "Ole Lashes. Hiszpania",
      placeInContest: "II miejsce 1:1, II miejsce 2/3d Professional",
      category: "",
    },
    {
      award: "VIP Asian Lash online competition, Wietnam",
      placeInContest:
        "I miejsce 4-6d Expert, nagroda specjalna od Elizy Borowiak, Jess Martin, Anny Tornhult, Quynh Pham",
      category: "",
    },
    {
      award: "Love4lash Competition Ireland",
      placeInContest: "I miejsce classic 1:1 Expert",
      category: "",
    },
    {
      award: "Worldwide Beauty Championship, Kanada",
      placeInContest:
        "I miejsce Color Lashes Expert, I miejsce Tint Eyebrows Expert, I miejsce Classic Lashes Expert, II miejsce 3D Volume Lashes Expert, Nagroda specjalna od Modesta Kanapinskiene za stylizację brwi",
      category: "",
    },
  ],
};
