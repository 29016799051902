import styled from "styled-components";

const MarginBox = styled.div`
    margin-top: 15vh;
`


const Margin = () => (
    <MarginBox />
)

export default Margin;
