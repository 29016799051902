export const esteticsMed = {
  title: "Medycyna Estetyczna",
  list: [
    {
      serviceName: "Mezoterapia mikroigłowa - ciało",
      shortContent: "Cena za obszar wielkości dłoni.",
      price: "200 zł",
      time: "90 minut",
      friendlyUrl: "mezoterapia-mikroigłowa-cialo",
    },
    {
      serviceName: "Mezoterapia mikroigłowa DERMAPEN",
      shortContent: "Mezoterapia mikroigłowa DERMAPEN",
      price: "250",
      time: "60 minut",
      friendlyUrl: "mezoterapia-mikroigłowa-dermapen",
    },
    {
      serviceName: "Mezoterapia mikroigłowa DERMAPEN twarz + szyja",
      shortContent: "Mezoterapia mikroigłowa DERMAPEN twarz + szyja",
      price: "290",
      time: "90 minut",
      friendlyUrl: "mezoterapia-mikroigłowa-dermapen-twarz-szyja",
    },
    {
      serviceName: "Mezoterapia mikroigłowa okolicy oczu",
      shortContent: "Mezoterapia mikroigłowa okolicy oczu",
      price: "150",
      time: "60 minut",
      friendlyUrl: "mezoterapia-mikroigłowa-okolicy-oczu",
    },
    {
      serviceName: "Mezoterapia mikroigłowa twarzy, szyi i dekoltu",
      shortContent: "Mezoterapia mikroigłowa twarzy, szyi i dekoltu",
      price: "350",
      time: "90 minut",
      friendlyUrl: "mezoterapia-mikroigłowa-okolicy-oczu",
    },
  ],
};

export const terapeutics = {
  title: "Terapie lecznicze",
  list: [
    {
      serviceName: "Dermapen + gen factor",
      shortContent:
        "Gen Factor to innowacyjny preparat wykorzystywany w medycynie i kosmetologii komórkowej służący do kompleksowej odbudowy i regeneracji skóry. Wspomaga wzrost komórek i tkanek dzięki stymulacji tworzenia się DNA. Pod jego wpływem skóra zaczyna wykształcać nie tylko nowe włókna kolagenowe, ale też całą macierz tkankową, łącznie z gaszeniem stanu zapalnego. Gen Factor służy do dużych i małych zadań, które mają na celu odbudowę skóry. Preparat posiada udokumentowane działanie od terapii przeciwstarzeniowej, trądziku, trudno gojących się ran (np. oparzenia, owrzodzenia), po blizny potrądzikowe, pooparzeniowe, ubytki w tkankach, rozstępy itp.",
      price: "200 zł",
      time: "90 minut",
      friendlyUrl: "Dermapen-gen-factor",
    },
  ],
};

export const lashes = {
  title: "Stylizacja Rzęs - zabiegi regenerujące rzęsy",
  list: [
    {
      serviceName: "Henna rzęs",
      shortContent: "Koloryzacja rzęs trwałą farbą żelową.",
      price: "20 zł",
      time: "20 minut",
      friendlyUrl: "henna-rzes",
    },
    {
      serviceName: "Lifting laminacja botox rzęs",
      shortContent:
        "Zabieg polegający na podkręceniu, odżywieniu, odbudowaniu i przyciemnieniu rzęs naturalnych. Alternatywa dla przedłużania rzęs. Efekt utrzymuje się do nawet 3 miesięcy.",
      price: "160 zł",
      time: "90 minut",
      friendlyUrl: "lifting-laminacja-botox-rzes",
    },
  ],
};

export const specialLashes = {
  title: "Stylizacja Rzęs - przedłużanie i zagęszczanie",
  list: [
    {
      serviceName: 'Przedłużanie rzęs metodą "Gęsto" (5-8D) ',
      shortContent:
        "“Jakiego efektu Pani oczekuje? Gęsto, mocno, czarno, dużo” To jest dokładnie ten zabieg! Przedłużanie rzęs metodą objętościową 5D  +  lub inaczej zwaną Hollywood/ Russian Volume/Mega Volume polega na przyklejeniu kępki stworzonej przez stylistkę składającej się z kilku rzęs sztucznych do jednej rzęsy naturalnej (5-8 rzęs w kępce). Daje to efekt mocnego przyciemnienia powieki, zagęszczenie rzęs. Metodę dobieramy do ilości i jakości rzęs naturalnych i oczekiwań klientki. Najczęściej wybierany efekt przez klientki, które lubią się mocno malować.",
      price: "250 zł",
      time: "130 minut",
      friendlyUrl: "przedluzanie-rzes-gesto-5d-8d",
    },
    {
      serviceName: 'Przedłużanie rzęs metodą "Naturalnie ale widać" (3-4D)',
      shortContent:
        "“Jakiego efektu Pani oczekuje? Naturalnie, tak żeby było widać. Tylko bez wielkich wachlarzy ale też nie za lekko” To jest dokładnie ten zabieg! Przedłużanie rzęs metodą 3:1 lub 4:1 inaczej zwaną 3D/4D polega na przyklejeniu kępki stworzonej przez stylistkę składającej się z 3 lub 4 rzęs sztucznych do jednej rzęsy naturalnej. Daje to efekt delikatnie zagęszczonych rzęs. Metodę dobieramy do ilości i jakości rzęs naturalnych i oczekiwań klientki. Najczęściej wybierany efekt wśród naszych klientek.",
      price: "210 zł",
      time: "130 minut",
      friendlyUrl: "przedluzanie-rzes-naturalnie-ale-nie-widac-3d-4d",
    },
    {
      serviceName: 'Przedłużanie rzęs metodą "Naturalnie" (1-2D) ',
      shortContent:
        "“Jakiego efektu Pani oczekuje? Naturalnie, tak żeby było widać. Tylko bez wielkich wachlarzy ale też nie za lekko” To jest dokładnie ten zabieg! Przedłużanie rzęs metodą 1:1 lub 2:1 lub inaczej zwaną 2D polega na przyklejeniu pojedynczej rzęsy lub kępki stworzonej przez stylistkę składającej się z 2 rzęs sztucznych do jednej rzęsy naturalnej. Daje to efekt delikatnie wytuszowanych rzęs ale nadal naturalnych. Metodę dobieramy do ilości i jakości rzęs naturalnych i oczekiwań klientki. Najczęściej wybierany efekt wśród naszych klientek.",
      price: "180 zł",
      time: "120 minut",
      friendlyUrl: "przedluzanie-rzes-naturalnie-1d-2d",
    },
    {
      serviceName: "Przedłużanie rzęs metodą Kim Kardashian",
      shortContent:
        "Najnowsze trendy stylizacji rzęs. Metoda Kim Kardashian polega na zagęszczeniu rzęs metodą min. 4D z dodatkowymi dłuższymi promieniami. Zalecane uzupełnienie po ok 3 tygodniach w celu zachowania świeżego wyglądu stylizacji.",
      price: "250 zł",
      time: "150 minut",
      friendlyUrl: "przedluzanie-rzes-kim-kardashian",
    },
    {
      serviceName: 'Uzupełnienie rzęs  metodą "Gęsto"',
      shortContent:
        "Uzupełnienie do 4 tygodni, minimalna ilość rzęs syntetycznych pozostałych na oku ok 30%.",
      price: "220 zł",
      time: "120 minut",
      friendlyUrl: "uzupelnianie-rzes-gesto",
    },
    {
      serviceName: 'Uzupełnienie rzęs metodą " Naturalnie ale widać"',
      shortContent:
        "Uzupełnienie do 4 tygodni, minimalna ilość rzęs syntetycznych pozostałych na oku ok 30%.",
      price: "180 zł",
      time: "120 minut",
      friendlyUrl: "uzupelnianie-rzes-naturalnie-ale-nie-widac",
    },
    {
      serviceName: 'Uzupełnienie rzęs metodą "Naturalnie"',
      shortContent:
        "Uzupełnienie do 4 tygodni, minimalna ilość rzęs syntetycznych pozostałych na oku ok 30%.",
      price: "160 zł",
      time: "100 minut",
      friendlyUrl: "uzupelnianie-rzes-naturalnie",
    },
    {
      serviceName: "Uzupełnienie rzęs metodą Kim Kardashian",
      shortContent:
        "Uzupełnienie do 4 tygodni, minimalna ilość rzęs syntetycznych pozostałych na oku ok 30%.",
      price: "220 zł",
      time: "120 minut",
      friendlyUrl: "uzupelnianie-rzes-kim-kardashian",
    },
    {
      serviceName: "Zdjęcie rzęs",
      shortContent: "Zdjęcie rzęs bez ponownej aplikacji",
      price: "40 zł",
      time: "20 minut",
      friendlyUrl: "zdjecie-rzes",
    },
    {
      serviceName: "przedłużanie rzęs metodą 1:1",
      shortContent: "Przedłużanie rzęs metodą 1:1",
      price: "160 zł",
      time: "90 minut",
      friendlyUrl: "przedluzanie-rzes-1-do-1",
    },
    {
      serviceName: "Uzupełnienie rzęs  metodą 1:1",
      shortContent: "Uzupełnienie rzęs  metodą 1:1",
      price: "140 zł",
      time: "90 minut",
      friendlyUrl: "uzupelnianie-rzes-1-do-1",
    },
  ],
};

export const cosmetics = {
  title: "Kosmetyka - Zabiegi pielęgnacyjne",
  list: [
    {
      serviceName: "Darsonvalizacja do zabiegu",
      shortContent:
        "Darsoval to urządzenie emitujace prąd, który wytwarza ozon. Ozon ma działanie dezynfekujace, dotleniajace i regenerujące skórę. Zabieg polecany do wykonywania jednorazowo przy pojedynczych stanas zapalnych lub w serii przy trądziku, skórze zmęczonej, szarej, przy terapii wzmacniającej włos i zapobiegającej nadmiernemu wypadaniu włosów.",
      price: "25 zł",
      time: "10 minut",
      friendlyUrl: "darsonvalizacja",
    },
    {
      serviceName: "ETERNAL GOLD Premium luksusowy zabieg na twarz",
      shortContent:
        "W dzisiejszych dość ponurych czasach, których nie rozwesela pogoda chcemy podzielić się naszą pozytywną energią. Jeśli potrzebujecie odpoczynku, relaksu a przy okazji poprawy kondycji skóry to jesteście w dobrych rękach. Z ogromną radością przedstawiamy nowość ZABIEG ETERNAL GOLD Odkryj działanie 24-karatowego złota koloidalnego. Każda z nas zasługuje na czas dla siebie. Zadbamy o intensywną pielęgnację i odmłodzenie Waszej skóry, ale również zapewnimy relaks i oderwanie się od codzienności. Zabieg z użyciem ekskluzywnych produktów z naturalnym „eliksirem młodości”, wzbogacony o relaksujący masaż twarzy oraz sonoforezę dla głębszego wnikania składników odżywczych. Dodatkowo wprowadzamy strefę ciszy w salonie dla zwiększenia komfortu. Zabieg Eternal Gold sprawdza się doskonale jako zabieg bankietowy ze względu na błyskawiczne rezultaty. Jesteśmy przekonane, że pokochacie ten zabieg. A dla tych z Was, które będą chciały dłużej czuć się piękne i młode zalecamy serie zabiegów oraz pielęgnacje domową przy użyciu kosmetyków z serii Eternal Gold. Zalecenia: ✅dla osób po 30 roku życia ✅dla skóry dojrzałej ✅dla skóry zmęczonej ✅dla skóry odwodnionej i suchej ✅dla skóry pozbawionej jędrności Efekty: 💎wygładzenie i regeneracja 💎spłycenie bruzd i zmarszczek 💎nawilżona i promienna skóra 💎zniwelowanie przebarwień i niedoskonałości 💎skóra napięta i pełna blasku Wersja premium zawiera dodatkową złotą maskę oraz masaż dłoni.",
      price: "280 zł",
      time: "120 minut",
      friendlyUrl: "eternal-gold",
    },
    {
      serviceName: "Oczyszczanie manualne pleców",
      shortContent: "Oczyszczanie manualne pleców",
      price: "200 zł",
      time: "90 minut",
      friendlyUrl: "oczyszczanie-manualne-plecow",
    },
    {
      serviceName: "Oczyszczanie manualne twarzy",
      shortContent: "Oczyszczanie manualne twarzy",
      price: "150 zł",
      time: "60 minut",
      friendlyUrl: "oczyszczanie-manualne-twarzy",
    },
    {
      serviceName: "Zabieg z kwasami",
      shortContent:
        "Zabieg z wykorzystaniem kwasów Gen Factor pH : kwas nikotynowy, kwas rozmarynowy, monokwas kawowy oraz kwas huminowy. Kwasy dobierane są do indywidualnych potrzeb skóry po konsultacji poprzedzającej zabieg. Wskazania do zabiegu: Likwidacja drobnych zmarszczek Poprawa jakości skóry (gęstości i elastyczności) Wyrównanie kolorytu skóry Delikatne wyrównanie powierzchni skóry Nadanie blasku i gładkości skórze Przywrócenie odpowiedniego napięcia Poprawa owalu twarzy Nawilżenie Redukcja blizn i rozstępów Trądzik Najlepsze efekty daje seria zabiegów, a ich ilość jest zależna od zaawansowania problemu skórnego.",
      price: "200 zł",
      time: "60 minut",
      friendlyUrl: "zabieg-z-kwasami",
    },
  ],
};

export const eyebrowns = {
  title: "Stylizacja brwii",
  list: [
    {
      serviceName: "Gold Brows",
      shortContent:
        "Autorski zabieg stworzony z myślą o pielęgnacji zarówno włosa jak i skóry. Luksusowy rytuał obejmuje geometrię i architekturę brwi, złotą odżywczą i rozświetlającą pastę, wzbogaconą o witaminy i oleje farbę mniej lub bardziej barwiącą skórę (w zależności od potrzeb i preferencji klientki), złoty naturalny Wosk do depilacji (o ile nie ma przeciwwskazań) a także delikatny masaż olejkiem. Na koniec wykonywany jest delikatny makijaż okolicy brwi i ewentualne ułożenie włosków kosmetykiem utrwalającym kształt. Cały zabieg wykonywany jest najwyższej jakości produktami by skóra po była aksamitna w dotyku a włoski miękkie i odżywione 😊",
      price: "130 zł",
      time: "60 minut",
      friendlyUrl: "gold-brows",
    },
    {
      serviceName: "Henna brwi + henna rzęs z Lycon",
      shortContent: "Henna brwi + henna rzęs z Lycon",
      price: "60 zł",
      time: "30 minut",
      friendlyUrl: "henna-brwi-lycon",
    },
    {
      serviceName: "Henna brwi z regulacją",
      shortContent:
        "Stylizacja brwi farbką wraz z regulacją woskiem Lycon Hot Wax i/lub pęsetą. Efekt może być naturalny lub mocniejszy w zależności od użytej farby.",
      price: "50 zł",
      time: "30 minut",
      friendlyUrl: "henna-brwi-z-regulacja",
    },
    {
      serviceName: "Regulacja brwi",
      shortContent:
        "Regulacja brwi za pomocą wosku twardego Lycon Hot Wax i/lub pęsety. Zabieg wykończony balsamem lub makijażem rozświetlającym skórę wokół brwi",
      price: "30 zł",
      time: "15 minut",
      friendlyUrl: "regulacja-brwi",
    },
    {
      serviceName: "Laminacja brwi",
      shortContent:
        "Zabieg polegający na ułożeniu, ujarzmieniu niesfornych włosków, koloryzacji i odżywieniu brwi. Efekt utrzymuje się do 2 miesięcy. Pomiędzy zabiegami można wykonywać koloryzację i regulację brwi.",
      price: "130 zł",
      time: "60 minut",
      friendlyUrl: "laminacja-brwi",
    },

    {
      serviceName: "Stylizacja brwi henną pudrową",
      shortContent:
        "Zaawansowana stylizacja brwi henną pudrową, intensywność efektu dobierana jest do oczekiwań klientki. Henna o przedłużonej trwałości nawet do 6 tygodni na włosie, do 14 dni na skórze. Depilacja zbędnych włosków najlepszym woskiem na świecie Lycon, który usuwa nawet meszek bez podrażniania skóry. Wykończenie balsamem lub makijażem rozświetlającym skórę wokół brwi.",
      price: "100 zł",
      time: "60 minut",
      friendlyUrl: "stylizacja-brwi-henna-pudrowa",
    },
    {
      serviceName: "Stylizacja brwi trwałą farbą BRONSUN",
      shortContent: "Stylizacja brwi trwałą farbą BRONSUN",
      price: "90 zł",
      time: "60 minut",
      friendlyUrl: "stylizacja-brwi-trwala-farba-bronsun",
    },
  ],
};

export const tan = {
  title: "Opalanie natryskowe",
  list: [
    {
      serviceName: "Opalanie natryskowe Lyco-Bronze   +  peeling",
      shortContent:
        "Naturalna opalenizna bez słońca 😁  Dla kogo jest ten zabieg?  ✔️Przed wielkimi wyjściami (ślub, wesele, sesja zdjęciowa) ✔️Dla pracoholików siedzących wiecznie w pracy (jak ja 😂brak czasu na opalanie) ✔️Przed wyjazdem na urlop, żeby nie zlać się z kolorem piasku ✔️Dla osób z przeciwwskazaniami do opalania na słońcu lub solarium ✔️Dla kobiet w ciąży Efekt utrzymuje się do 7-10 dni a czasem nawet do 14 dni 😱 Zanika stopniowo bez plam, zacieków.  Przed zabiegiem wykonujemy dokładny peeling całego ciała i twarzy produktami Lycon. Zabieg wykonywany jest najwyższej jakości produktami o naturalnym składzie 🌱",
      price: "120 zł",
      time: "45 minut",
      friendlyUrl: "lyco-bronze-peeling",
    },
    {
      serviceName: "Opalanie natryskowe całe ciało  ",
      shortContent:
        "Naturalna opalenizna bez słońca 😁  Dla kogo jest ten zabieg?  ✔️Przed wielkimi wyjściami (ślub, wesele, sesja zdjęciowa) ✔️Dla pracoholików siedzących wiecznie w pracy (jak ja 😂brak czasu na opalanie) ✔️Przed wyjazdem na urlop, żeby nie zlać się z kolorem piasku ✔️Dla osób z przeciwwskazaniami do opalania na słońcu lub solarium ✔️Dla kobiet w ciąży Efekt utrzymuje się do 7-10 dni a czasem nawet do 14 dni 😱 Zanika stopniowo bez plam, zacieków.  Przed zabiegiem wykonujemy dokładny peeling całego ciała i twarzy produktami Lycon. Zabieg wykonywany jest najwyższej jakości produktami o naturalnym składzie 🌱",
      price: "100 zł",
      time: "30 minut",
      friendlyUrl: "cale-cialo",
    },
  ],
};

export const depilation = {
  category: "Depilacja",
  list: [
    {
      serviceName: "Bikini pachwiny Lycon",
      shortContent:
        "Depilacja woskiem twardym Lycon zapewnia długotrwałe efekty oraz zminimalizowanie odczuć bólowych. Co więcej pozwala na wydepilowanie już 2 mm włosków.  Zabieg wykonany przez wykwalifikowanego kosmetologa. Optymalna długość włosa ok 1-4mm. Obszar zabiegowy bikini pachwiny : pachwiny do linii bielizny lub do 1cm głębiej   +  ścieżka od górnej linii bielizny do pępka  Posiadamy również wosk hipoalergiczny , więc jeśli masz alergię na wosk pszczeli lub żywice prosimy o informację. Opcja hipoalergiczna nie wiąże się z dodatkową opłatą",
      price: "70 zł",
      time: "15 minut",
      friendlyUrl: "bikini-pachwiny-lycon",
    },
    {
      serviceName: "Nogi   +  bikini brazylijskie",
      shortContent: "Nogi   +  bikini brazylijskie",
      price: "220 zł",
      time: "90 minut",
      friendlyUrl: "nogi-bikini-brazylijskie",
    },
    {
      serviceName: "nogi   +  bikini klasyczne (pachwiny)",
      shortContent: "Nogi   +  bikini klasyczne (pachwiny)",
      price: "160 zł",
      time: "70 minut",
      friendlyUrl: "nogi-bikini-klasyczne",
    },
    {
      serviceName: "nogi   +  pachy   +  bikini brazylijskie",
      shortContent: "Nogi   +  pachy   +  bikini brazylijskie",
      price: "280 zł",
      time: "90 minut",
      friendlyUrl: "nogi-pachy-bikini-brazylijskie",
    },
    {
      serviceName: "nogi   +  pachy   +  bikini klasyczne",
      shortContent: "Nogi   +  pachy   +  bikini klasyczne",
      price: "230 zł",
      time: "70 minut",
      friendlyUrl: "nogi-pachy-bikini-klasyczne",
    },
    {
      serviceName: "pachy   +  bikini brazylijskie",
      shortContent: "Pachy   +  bikini brazylijskie",
      price: "165 zł",
      time: "40 minut",
      friendlyUrl: "pachy-bikini-brazylijskie",
    },
    {
      serviceName: "pachy  +  bikini klasyczne",
      shortContent: "Pachy  +  bikini klasyczne",
      price: "110 zł",
      time: "20 minut",
      friendlyUrl: "pachy-bikini-klasyczne",
    },
    {
      serviceName: "łydki  + bikini brazylijskie",
      shortContent: "Łydki  + bikini brazylijskie",
      price: "185 zł",
      time: "50 minut",
      friendlyUrl: "lydki-bikini-brazylijskie",
    },
    {
      serviceName: "Łydki  +  bikini klasyczne",
      shortContent: "Łydki  + bikini klasyczne",
      price: "130 zł",
      time: "40 minut",
      friendlyUrl: "lydki-bikini-klasyczne",
    },
    {
      serviceName: "łydki  + pachy  + bikini brazylijskie",
      shortContent: "Łydki  + pachy  + bikini brazylijskie",
      price: "235 zł",
      time: "60 minut",
      friendlyUrl: "lydki-pachy-bikini-brazylijskie",
    },
    {
      serviceName: "Łydki  + pachy  + bikini klasyczne",
      shortContent: "Łydki  + pachy  + bikini klasyczne",
      price: "180 zł",
      time: "40 minut",
      friendlyUrl: "lydki-pachy-bikini-klasyczne",
    },
  ],
};
