import Navigation from "../../components/navigationWithBackground/navigationBar/navigationBar";
import MarginBox from "../../components/margin/margin";
import "./CoursesPage.scss";
import { lashes, eyebrowns, depilation, master } from "../../data/courses";
import { useState } from "react";
import { Loader } from "../../components/preloader/loader";

const CoursesPage = () => {
  const [loading, setLoading] = useState(true);

  setTimeout(() => {
    setLoading(false);
    console.log(loading);
  }, 1300);

  return (
    <>
      {loading && <Loader />}
      <Navigation />
      <MarginBox />
      <section className="list">
        <div className="container">
          <h2>{lashes.title}</h2>
          {lashes.list.map((element) => (
            <div className="list-box-container">
              <div className="list-box">
                <h3>{element.serviceName}</h3>
                <p>{element.shortContent}</p>
                <div>
                  <a href={`szkolenia/rzesy/${element.friendlyUrl}`}>
                    Szczegóły
                  </a>
                  <a href={`zamowienie/rzesy/${element.friendlyUrl}`}>
                    zarezerwuj
                  </a>
                </div>
              </div>
              <div className="list-box">
                <h3>Informacje:</h3>
                <span>
                  Czas trwania: {element.time}
                  <br />
                </span>
                <span>
                  Cena w grupie: {element.priceGroup} zł
                  <br />
                  Cena indywidualnie: {element.priceIndividual} zł
                  <br />
                </span>
              </div>
            </div>
          ))}
        </div>
        <div className="container">
          <h2>{eyebrowns.title}</h2>
          {eyebrowns.list.map((element) => (
            <div className="list-box-container">
              <div className="list-box">
                <h3>{element.serviceName}</h3>
                <p>{element.shortContent}</p>
                <div>
                  <a href={`szkolenia/brwii/${element.friendlyUrl}`}>
                    Szczegóły
                  </a>
                  <a href={`zamowienie/brwii/${element.friendlyUrl}`}>
                    zarezerwuj
                  </a>
                </div>
              </div>
              <div className="list-box">
                <h3>Informacje:</h3>
                <span>
                  Czas trwania: {element.time}
                  <br />
                </span>
                <span>
                  Cena w grupie: {element.priceGroup} zł
                  <br />
                  Cena indywidualnie: {element.priceIndividual} zł
                  <br />
                </span>
              </div>
            </div>
          ))}
        </div>
        <div className="container">
          <h2>{master.title}</h2>
          {master.list.map((element) => (
            <div className="list-box-container">
              <div className="list-box">
                <h3>{element.serviceName}</h3>
                <p>{element.shortContent}</p>
                <div>
                  <a href={`szkolenia/master/${element.friendlyUrl}`}>
                    Szczegóły
                  </a>
                  <a href={`zamowienie/master/${element.friendlyUrl}`}>
                    zarezerwuj
                  </a>
                </div>
              </div>
              <div className="list-box">
                <h3>Informacje:</h3>
                <span>
                  Czas trwania: {element.time}
                  <br />
                </span>
                <span>
                  Cena w grupie: {element.priceGroup} zł
                  <br />
                  Cena indywidualnie: {element.priceIndividual} zł
                  <br />
                </span>
              </div>
            </div>
          ))}
        </div>
        <div className="container">
          <h2>{depilation.title}</h2>
          {depilation.list.map((element) => (
            <div className="list-box-container">
              <div className="list-box">
                <h3>{element.serviceName}</h3>
                <p>{element.shortContent}</p>
                <div>
                  <a href={`szkolenia/depilacja/${element.friendlyUrl}`}>
                    Szczegóły
                  </a>
                  <a href={`zamowienie/depilacja/${element.friendlyUrl}`}>
                    zarezerwuj
                  </a>
                </div>
              </div>
              <div className="list-box">
                <h3>Informacje:</h3>
                <span>
                  Czas trwania: {element.time}
                  <br />
                </span>
                <span>
                  Cena w grupie: {element.priceGroup} zł
                  <br />
                  Cena indywidualnie: {element.priceIndividual} zł
                  <br />
                </span>
              </div>
            </div>
          ))}
        </div>
      </section>
    </>
  );
};

export default CoursesPage;
