import { useParams } from "react-router-dom";

import Navigation from "../../components/navigationWithBackground/navigationBar/navigationBar";
import MarginBox from "../../components/margin/margin";
import "./Order.scss";
import { depilation, eyebrowns, lashes, master } from "../../data/courses";
import { useState } from "react";
import { Loader } from "../../components/preloader/loader";

const OrderCourse = () => {
  const { friendlyUrl, category } = useParams();
  const coursesList = {
    depilacja: depilation,
    brwii: eyebrowns,
    rzesy: lashes,
    master: master,
  };

  const selectService = coursesList[category].list.filter(
    (element) => element.friendlyUrl === friendlyUrl
  )[0];

  const [loading, setLoading] = useState(true);

  setTimeout(() => {
    setLoading(false);
    console.log(loading);
  }, 1300);

  console.log("tutaj", friendlyUrl, category);

  return (
    <>
      {loading && <Loader />}
      <Navigation />
      <MarginBox />
      <section className="list">
        <div className="service-container">
          <h2 className="serviceName">Rezerwacja</h2>
          <h4>
            Po opłaceniu należy skontaktować się z naszym salonem w celu
            umówienia terminu szkolenia.
            <br /> Potwierdzenie udanej transakcji zostanie przesłane na maila
          </h4>
          <form
            onSubmit={() =>
              alert("Coś poszło nie tak. Skontaktuj się z naszym salonem.")
            }
            action="/"
            className="list-box-container-single"
          >
            <label for="service">Nazwa szkolenia</label>
            <input
              required
              name="service"
              type="text"
              readOnly
              value={selectService.serviceName}
            />
            <label for="option">Tryb szkolenia</label>
            <select required name="option">
              <option>Indywidualnie: {selectService.priceIndividual} zł</option>
              <option>Grupowo: {selectService.priceGroup} zł</option>
            </select>
            <label for="names">Imię i nazwisko</label>
            <input
              required
              name="names"
              type="text"
              placeholder="Wpisz swoje imię i nazwisko"
              minLength="8"
              maxLength="128"
            />
            <label for="phone">Telefon kontaktowy (bez kierunkowego):</label>
            <input
              required
              name="phone"
              type="number"
              placeholder="Wpisz swój numer telefonu"
              min="411111111"
              max="999999999"
            />
            <label for="email">E-mail:</label>
            <input
              required
              name="phone"
              type="email"
              placeholder="Wpisz swój e-mail"
              minLength="8"
              maxLength="175"
            />
            <div className="checkbox-container">
              <input required type="checkbox" id="legacy" name="legacy" />
              <label for="legacy">
                Oświadczam, że wyrażam zgodę na przetwarzanie danych w celu
                obsługi rezerwacji miejsca na szkoleniu oraz że przyjąłem do
                wiadomości, że muszę skontaktować się z salonem w celu umówienia
                terminu
              </label>
            </div>
            <div>
              <input type="submit" value="Opłać przez przelewy24" />
            </div>
          </form>
        </div>
      </section>
    </>
  );
};

export default OrderCourse;
