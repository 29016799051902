import ContactData from "../data/data";
import HoursData from "../hours/hours";
import SocialsBox from "../socials/socials";
import "./Contact.scss";

export const ContactInfo = () => (
  <div className="contact-container">
    <ContactData />
    <HoursData />
    <SocialsBox />
  </div>
);
